import classNames from 'classnames';
import { Section, SectionContainer } from '@components/Section';
import HeroButton from '../Hero/HeroButton';

import Styles from './WhyOnDemandTeams.module.scss';

const WhyOnDemandTeams = ({ survey }) => {
  const { metric, schedule } = survey;
  return (
    <SectionContainer>
      <Section
        title={survey?.title}
        subtitle={survey?.description}
        textColor={Section.Color.WHITE}
        titleClass={classNames(Styles.margin, Styles.override)}>
        <div className={Styles.list}>
          <div className={Styles.item}>
            <div className={Styles.percentage}>{`${metric.percentage}`}</div>
            <p className={Styles.description}>{metric.description}</p>
            <p className={Styles.sourceText}>{metric.sourceText}</p>
          </div>
          <div className={Styles.card}>
            <p className={Styles.description2}>{schedule.description}</p>
            <HeroButton
              className={Styles.actionBtn}
              type={HeroButton.Type.BLUE}
              title={schedule.actionText}
              to={schedule.actionLink}>
              {schedule.actionText}
            </HeroButton>
          </div>
        </div>
      </Section>
    </SectionContainer>
  );
};

export default WhyOnDemandTeams;
