import PropTypes from 'prop-types';
import { useRef } from 'react';
import classNames from 'classnames';
import Styles from './Styles.module.scss';
import { useCountUp } from 'react-countup';

const Item = ({
  className,
  metricClassName,
  captionClassName,
  number,
  metric,
  caption,
  captionColor,
}) => {
  const countUpRef = useRef(null);
  useCountUp({
    ref: countUpRef,
    start: number / 2,
    end: number,
    duration: 4,
    separator: ',',
    suffix: metric,
    delay: 0,
  });
  return (
    <div className={classNames(Styles.item, className)}>
      <p className={classNames(Styles.number, metricClassName)}>
        <span ref={countUpRef} />
      </p>
      <p className={classNames(Styles.caption, captionClassName)} style={{ color: captionColor }}>
        {caption}
      </p>
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number,
  metric: PropTypes.string,
  caption: PropTypes.string,
};
export default Item;
