import classNames from 'classnames';
import { Section, SectionContainer } from '@components/Section';
import Column from '@components/Column/Column';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './EnterpriseHowItWorks.module.scss';

const Item = ({ section }) => {
  return (
    <Column.List className={Styles.itemContainer}>
      <Column.Card className={Styles.item}>
        <Text element='h3' className={Styles.itemTitle}>
          {section?.title}
        </Text>
        <Text element='p' className={Styles.itemDescription}>
          {section?.description}
        </Text>
      </Column.Card>
      <Column.Card className={Styles.item}>
        <ul>
          {section?.list.map((item, index) => {
            return (
              <li key={index} className={Styles.listItem}>
                <Text className={Styles.textItem}>{item}</Text>
              </li>
            );
          })}
        </ul>
      </Column.Card>
    </Column.List>
  );
};

const EnterpriseHowItWorks = ({ className }) => {
  return (
    <SectionContainer anchor='how-it-works'>
      <Section className={classNames(Styles.section, className)}>
        <Text
          element='h2'
          className={Styles.title}
          size={Text.Size.SUBHEADLINE_LARGE}
          color={Text.Color.WHITE}>
          {`How Hire Digital Works`}
        </Text>
        <Text element='p' color={Text.Color.WHITE} className={Styles.subtitle}>
          {`Get the benefits of an in-house team and more`}
        </Text>
        <Text element='p' className={Styles.description}>
          {`Supercharge your growth. Scale your highest performing content marketing programs and build expertise from Day One to achieve better results for your business.`}
        </Text>
        <div className={Styles.itemWrapper}>
          <Item
            section={{
              title: 'A Customized Talent Plan For Your Business',
              description:
                "Hire Digital's specialists will customize a talent plan to meet your business objectives.",
              list: [
                'Audit of your current team composition and workflows, against your targets',
                'Present opportunities to add new expertise & capabilities within your teams',
                'Recommended team composition and on-demand talent with the right skills',
              ],
            }}
          />
          <Item
            section={{
              title: 'Curated Team With Relevant Skills and Channel Expertise',
              description:
                "We provide you the control needed to stay aligned with your team's culture and goals.",
              list: [
                'Pre-vetted talent matched to your team in as fast as 48 hours',
                'Serving over 27 verticals, work with talent specific to your industry, who have a deep understanding of your customers and target audience',
              ],
            }}
          />
          <Item
            section={{
              title: 'An Ongoing Talent Partner as You Scale Your Team',
              description:
                'A dedicated customer success manager will work closely with you to ensure that your team can focus on delivering results.',
              list: [
                'Risk-free guarantees to ensure talent are the best fit for your team',
                'Technical assistance for seamless talent onboarding',
                'A single point of contact to answer your queries',
              ],
            }}
          />
        </div>
      </Section>
    </SectionContainer>
  );
};

export default EnterpriseHowItWorks;
