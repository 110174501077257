import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Link from 'next/link';

// Custom Link component that offers backwards compatibility with GatsbyJS Link component

const CustomLink = ({
  to,
  href,
  as,
  shallow = false,
  children,
  className,
  activeClassName,
  ...props
}) => {
  const h = href || to;
  const router = useRouter();
  return (
    <Link href={h} as={as} shallow={shallow} prefetch={false}>
      <a className={classNames(className, router.asPath === h && activeClassName)} {...props}>
        {children}
      </a>
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default CustomLink;
