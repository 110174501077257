import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import MainLayout from '@components/Layout/Layout';
import Header from '@components/Header/EnterpriseHeader';

import contentcoData from '@data/pages/thecontentco.json';
import Metrics from '@components/Metrics/Metrics';
import ContentCoHero from '@components/PageComponents/Hero/ContentCoHero';
import { getUsers, mergeCardUsersData, limitUsersData } from '@helpers/users';
import { replaceJson } from '@helpers/utils';
import { getStoriesBySlug } from '@helpers/posts';

import NewEnterpriseHowItWorks from '@components/PageComponents/NewEnterpriseHowItWorks';
import Capabilities from '@components/PageComponents/ContentCoCapabilities';
import TeamWorkFlow from '@components/PageComponents/ContentCoTeamWorkFlow/TeamWorkFlow';
import WhyOnDemandTeams from '@components/PageComponents/ContentCoWhyTeams/WhyOnDemandTeams';
import CallToAction from '@components/PageComponents/CallToAction/CallToAction';
import PostSection from '@components/PageComponents/PostSection/PostSection';
import ClientTestimonial from '@components/ClientTestimonial/ClientTestimonial';

const RightTriangle = dynamic(() => import('@components/Parallax/RightTriangle'), {
  ssr: false,
});
const LeftTriangle = dynamic(() => import('@components/Parallax/LeftTriangle'), {
  ssr: false,
});

// const NewEnterpriseHowItWorks = dynamic(
//   () => import('@components/PageComponents/NewEnterpriseHowItWorks'),
//   { ssr: false }
// );
// const Capabilities = dynamic(() => import('@components/PageComponents/ContentCoCapabilities'));
// const TeamWorkFlow = dynamic(() =>
//   import('@components/PageComponents/ContentCoTeamWorkFlow/TeamWorkFlow')
// );
// const WhyOnDemandTeams = dynamic(() =>
//   import('@components/PageComponents/ContentCoWhyTeams/WhyOnDemandTeams')
// );

// const CallToAction = dynamic(() => import('@components/PageComponents/CallToAction/CallToAction'));

// const PostSection = dynamic(() => import('@components/PageComponents/PostSection/PostSection'));
// const ClientTestimonial = dynamic(() => import('@components/ClientTestimonial/ClientTestimonial'), {
//   ssr: false,
// });

const ContentCoPage = ({
  hero,
  head,
  benefits,
  survey,
  metrics,
  footer,
  midCtaSection,
  customerNames,
  cardUsers,
  posts,
  postData,
  ...props
}) => {
  return (
    <MainLayout
      header={Header}
      darkFooter
      footerData={footer}
      pageHead={head}
      variant={MainLayout.Variants.ENTERPRISE}>
      <ContentCoHero
        customerNames={customerNames}
        users={[]}
        title={hero.title}
        description={hero.description}
        brandCtaLinkTo={`/get-started`}
        brandCtaLabel={`Schedule a Consultation`}
      />
      <LeftTriangle />

      <NewEnterpriseHowItWorks />
      <Metrics type={Metrics.Type.ENTERPRISE_FULL} noRuler darkMode>
        {metrics.map((metric, index) => {
          return (
            <Metrics.Item
              key={index}
              number={metric.number}
              metric={metric.metric}
              caption={metric.caption}
            />
          );
        })}
      </Metrics>
      <RightTriangle />

      <Capabilities />
      <TeamWorkFlow />
      <LeftTriangle />

      <WhyOnDemandTeams survey={survey} />
      <ClientTestimonial isEnterprise />
      <PostSection
        isEnterprise
        title='Keep up with the latest trends and strategies'
        posts={posts}
        postData={postData}
      />
      <CallToAction isEnterprise />
    </MainLayout>
  );
};

ContentCoPage.propTypes = {
  hero: PropTypes.object,
  head: PropTypes.object,
  footer: PropTypes.object,
  midCtaSection: PropTypes.object,
  customerNames: PropTypes.array,
  cardUsers: PropTypes.array,
  posts: PropTypes.array,
  postData: PropTypes.array,
};
export default ContentCoPage;

export const getStaticProps = async (context) => {
  const pageData = replaceJson(contentcoData);

  const { users = [], ...restPageData } = pageData;
  const jsonUsers = [];
  const userUuids = users.filter(({ uuid }) => !!uuid).map(({ uuid }) => uuid);
  let apiUsers = [];
  if (userUuids.length) {
    apiUsers = await getUsers(userUuids);
  }

  const posts = pageData.posts;
  const articles = posts.map(({ slug }) => slug);
  const postData = await getStoriesBySlug(articles);

  const cardUsers = limitUsersData(mergeCardUsersData(jsonUsers, users, apiUsers));

  return {
    props: { ...restPageData, cardUsers, postData },
    revalidate: 7200,
  };
};
