import { memo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Styles from '../Header.module.scss';

const MobileMenu = ({ onMenuToggle, children, watchAnchor: anchorClicked, resetAnchor }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (anchorClicked) {
      onMenuToggle?.(false);
      setVisible(false);
      resetAnchor?.();
    }
  }, [anchorClicked]);

  const handleMenuToggle = () => {
    onMenuToggle?.(!visible);
    setVisible(!visible);
  };

  return (
    <>
      <div className={Styles.mobileMenu}>
        <div className={Styles.mobileButton} onClick={handleMenuToggle}>
          {visible ? `Close` : `Menu`}
        </div>
        {visible && (
          <>
            <div className={Styles.mobileList}>{children}</div>
            {createPortal(
              <div className={Styles.mobileMask} onClick={handleMenuToggle} />,
              document.body
            )}
          </>
        )}
      </div>
      <div className={Styles.defaultMenu}>{children}</div>
    </>
  );
};

export default memo(MobileMenu);
