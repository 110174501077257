import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '@hiredigital/ui/Text/Text';
import HeroButton from '../Hero/HeroButton';
import Img from '@components/Img';

import { SectionContainer } from '@components/Section';
import Styles from './CallToAction.module.scss';

const CallToAction = ({ analyticsData, talent, isEnterprise, className, ...props }) => {
  const talentType = talent || 'talent';
  const companyPath = isEnterprise ? '/get-started' : '/get-started';
  return (
    <SectionContainer
      type={
        isEnterprise
          ? SectionContainer.Type.ENTERPRISE_SPLIT
          : SectionContainer.Type.PROFESSIONAL_SPLIT
      }
      id='cta-box'>
      <div className={classNames(Styles.box)}>
        <Img
          src={`/img/abstract-building.webp`}
          alt='image'
          className={Styles.imageItem}
          wrapperClassName={Styles.imageContainer}
        />

        <Text element='p' size={Text.Size.H2}>
          {`Hire the best ${talentType} today`}
        </Text>
        <Text element='p' color={Text.Color.BLACK} className={Styles.companyDescription}>
          {`We provide you with the most qualified
                  ${talentType} to meet your needs. Get in touch to learn more.`}
        </Text>
        <div className={Styles.buttons}>
          <HeroButton
            type={HeroButton.Type.BLUE}
            className={Styles.btnApply}
            title='Work with Talent'
            href={{
              pathname: companyPath,
              query: { ...analyticsData },
            }}>
            {`Work With Talent`}
          </HeroButton>
          <HeroButton
            type={HeroButton.Type.BLUE_OUTLINE}
            className={Styles.btnApply}
            title='Apply As a Talent'
            href={{
              pathname: '/talent/register',
              query: { ...analyticsData },
            }}>
            {`Apply As a Talent`}
          </HeroButton>
        </div>
      </div>
    </SectionContainer>
  );
};

CallToAction.propTypes = {
  talent: PropTypes.string,
  className: PropTypes.string,
  analyticsData: PropTypes.object,
  isEnterprise: PropTypes.bool,
};

export default CallToAction;
