import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Img.module.scss';

const Img = ({ className, wrapperClassName, height, width, src, alt, style, ...props }) => {
  return (
    <span
      className={classNames(wrapperClassName, Styles.container)}
      style={{ display: 'inline-block', color: 'transparent', ...style, height, width }}>
      <img
        className={classNames(className, Styles.fadeIn)}
        loading='lazy'
        src={src}
        alt={alt}
        height={height}
        width={width}
        {...props}
      />
    </span>
  );
};

export default Img;
