import dynamic from 'next/dynamic';
import { Section, SectionContainer } from '@components/Section';
const BlogStories = dynamic(() => import('@components/PageComponents/BlogStories'), {
  ssr: false,
});
const PostSection = ({ postData, isEnterprise, title = `Read Hire Digital Stories` }) => {
  return (
    <SectionContainer data-test-id={`post-section`}>
      <Section textColor={isEnterprise ? Section.Color.WHITE : undefined} title={title}>
        <BlogStories isEnterprise={isEnterprise} posts={postData} />
      </Section>
    </SectionContainer>
  );
};
export default PostSection;
