import classNames from 'classnames';

import { Section, SectionContainer } from '@components/Section';
import Img from '@components/Img';

import Styles from './TeamWorkFlow.module.scss';
const diagram = '/img/contentco/diagram-thecontentco.svg';
const mobileDiagram = '/img/contentco/diagram-thecontentco-mobile.svg';

const TeamWorkFlow = () => {
  return (
    <SectionContainer>
      <div className={Styles.padding}>
        <Section
          className={classNames(Styles.section, Styles.override)}
          title={`How our on-demand content marketing teams work with enterprises`}
          titleClass={classNames(Styles.title)}
          subtitle={``}>
          <span className={Styles.image}>
            <Img wrapperClassName={Styles.imageArea} src={diagram} />
          </span>
          <span className={Styles.mobileImage}>
            <Img wrapperClassName={Styles.imageArea} src={mobileDiagram} />
          </span>
        </Section>
      </div>
    </SectionContainer>
  );
};

export default TeamWorkFlow;
