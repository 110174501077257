// import classNames from 'classnames';
// import Text from '@hiredigital/ui/Text/Text';
import { Section, SectionContainer } from '@components/Section';
import InteractiveButton from '@components/InteractiveButton/InteractiveButton';
import Img from '@components/Img';
import Styles from './Capabilities.module.scss';
import Column from '@components/Column/Column';

const data = {
  title: 'Managed content marketing with the capabilities you need',
  description:
    'Hire Digital’s vetted network of experts spans the most in-demand skill sets in digital, marketing, and creative.',
  list: [
    {
      title: 'Content Strategy',
      oldDesc:
        'Partner with us to build your remote team of expert content strategists. This team then leverages the latest technology and data trends to analyze persona, research keywords, assess competitors, and audit your current content resources - preparing a solid content plan with the best chance of success.',
      description:
        "Partner with Hire Digital's wide network of expert content strategists to leverage the latest trends and technologies to audit your content resources, identify key trends, and build a path forward for your brand success.",
      viewLink: 'https://hiredigital.com/marketing/content-strategists',
      viewLinkText: 'View Content Strategists',
      image: '/img/contentco/strategy.svg',
    },
    {
      title: 'Search Engine Optimization (SEO)',
      oldDesc:
        'At Hire Digital, we understand that SEO is the cornerstone of any content marketing strategy. We offer vetted SEO strategist teams that can help you promote your content, get the right backlinks, boost your domain rankings, drive relevant traffic, optimize conversions, and improve your overall cost to acquire a customer.',
      description:
        'Hire vetted SEO strategy teams that can help you promote your content, get the right backlinks, drive relevant traffic, optimize conversions, and improve your overall cost to acquire a customer.',
      viewLink: 'https://hiredigital.com/marketing/seo',
      viewLinkText: 'View SEO Strategists',
      image: '/img/contentco/optimization.svg',
    },
    {
      title: 'Content Creation',
      oldDesc:
        'Work only with experienced content creators handpicked for their expertise in your niche, and with relevant writing styles. Our rigorous screening process ensures that the creators you work with are well-versed with the preferences and behavior of your target audiences, enabling you to generate traffic and optimize for conversions.',
      description:
        'Work  with experienced content creators handpicked for their expertise in your niche, and with relevant writing styles, enabling you to generate traffic and optimize for conversions',
      viewLink: 'https://hiredigital.com/marketing/content-writers',
      viewLinkText: 'View Content Creators',
      image: '/img/contentco/writer.svg',
    },
    {
      title: 'Lead Generation',
      oldDesc:
        'It all comes down to the number of leads your content is able to deliver. We offer access to a network of high-performing growth marketers that can help you build lead pipelines across multiple channels targeting different markets. Work with our growth marketers to start leveraging your content right away to deliver results.',
      description:
        'Access a network of high-performing growth marketers that can help you build lead pipelines across multiple channels targeting different markets.',
      viewLink: 'https://hiredigital.com/marketing/lead-generation',
      viewLinkText: 'View Lead Generation Experts',
      image: '/img/contentco/leadgen.svg',
    },
    {
      title: 'Video Creation',
      oldDesc:
        'Our exclusive talent pool can help you convey your brand’s story through videos. We have creators that specialize in explainer, demo, educational, hiring, testimonial, and many other videos. The team you choose with us will not only help you conceptualize and produce the video but also promote it for optimal ROI.',
      description:
        'Our exclusive talent pool can help you convey your brand’s story through one of many different video formats and help you conceptualize, produce, and promote it for optimal ROI.',
      viewLink: 'https://hiredigital.com/multimedia/video-producer',
      viewLinkText: 'View Video Creators',
      image: '/img/contentco/video.svg',
    },
    {
      title: 'Distribution & Analytics',
      description:
        'Work with experts to analyze the performance of your content marketing programs, as well as help you identify, and promote your content through the best possible channels.',
      viewLink: 'https://hiredigital.com/marketing/content-strategists',
      viewLinkText: 'View Distribution Marketers',
      image: '/img/contentco/distribution.svg',
    },
  ],
};

const Item = ({ title, description, viewLink, viewLinkText, image, idx }) => {
  return (
    <Column.Card className={Styles.card}>
      <Column.Image type={Column.Image.Type.ICON}>
        <Img height={75} width={75} src={image} />
      </Column.Image>
      <Column.Headline>{title}</Column.Headline>
      <Column.Content>{description}</Column.Content>
      {viewLinkText && (
        <Column.Action>
          <InteractiveButton
            className={Styles.action}
            type={InteractiveButton.Type.BLUE}
            title={viewLinkText}
            href={viewLink}>
            {viewLinkText}
          </InteractiveButton>
        </Column.Action>
      )}
    </Column.Card>
  );
};

const List = () => {
  const { title, description, list } = data;
  return (
    <SectionContainer>
      <Section title={title} subtitle={description} textColor={Section.Color.WHITE}>
        <Column.List isCardList>
          {list.map((v, index) => (
            <Item {...v} key={index} idx={index} />
          ))}
        </Column.List>
      </Section>
    </SectionContainer>
  );
};

export default List;
