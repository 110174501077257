import { Section, SectionContainer } from '@components/Section';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Styles.module.scss';
import Item from './Item';

const Metrics = ({ className, children, type, darkMode, noRuler }) => {
  return (
    <SectionContainer type={type}>
      <Section className={noRuler ? '' : Section.Styles.ruler}>
        <div className={classNames(Styles.container, className, darkMode && Styles.dark)}>
          {children}
        </div>
      </Section>
    </SectionContainer>
  );
};

Metrics.Item = Item;
Metrics.Type = SectionContainer.Type;
Metrics.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  darkMode: PropTypes.bool,
  noRuler: PropTypes.bool,
};

// Metrics.defaultProps = {
//   dark: false,
// };
export default Metrics;
