import classNames from 'classnames';
import PropTypes from 'prop-types';
import Hero from '../Hero';
import HeroButton from '../HeroButton';
import LogoSection from '@components/PageComponents/LogoSection/LogoSection';
import Styles from './Styles.module.scss';

const ContentCoHero = ({ title, description, customerNames }) => {
  return (
    <Hero parent={Hero.Page.NEW_ENTERPRISE} style={{ height: '800px' }}>
      <div className={classNames(Hero.Styles.heroContainer, Styles.hero)}>
        <h1 className={Hero.Styles.heroTitle}>{title}</h1>
        <p className={Hero.Styles.heroDescription}>{description}</p>
        <div className={Styles.heroCta}>
          <HeroButton type={HeroButton.Type.WHITE} title='' to='/get-started'>
            {`Contact Us Today`}
          </HeroButton>
        </div>
        <div className={Styles.heroBottom}>
          <LogoSection removeBackground opacity={70} speed={10} />
        </div>
      </div>
    </Hero>
  );
};

ContentCoHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  customerNames: PropTypes.array,
};

export default ContentCoHero;
