export const defaultHead = {
  title: `Hire Digital`,
  meta: [
    {
      name: 'image',
      content: 'http://cdn.hiredigital.com/open-graph/hd/image_opt.jpg',
    },
    {
      name: 'description',
      content: `Scale your content and digital marketing with Hire Digital's exclusive talent network. Rigorously screened for your most important projects.`,
    },
  ],
  link: [],
};
